/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'filter': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7 11h10v2H7zM4 7h16v2H4zm6 8h4v2h-4z"/>'
  }
})
